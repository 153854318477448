/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneRelatedArticleSlider as ResolvedStandaloneRelatedArticleSlider } from 'base/components/standalone/RelatedArticleSlider';

export const RelatedArticleSlider = ResolvedStandaloneRelatedArticleSlider;

export type RelatedArticleSliderProps = PropsFromComponent<typeof RelatedArticleSlider>;

export const RelatedArticleSliderWith = (extras: DynamicStandaloneExtras): typeof RelatedArticleSlider => {
    return function RelatedArticleSlider(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'RelatedArticleSlider');
        return <ResolvedStandaloneRelatedArticleSlider {...mergeProps({ options: { theme } }, props)} />;
    }
}