import { ArticleSlider, ArticleSliderProps } from '@/components/ArticleSlider';
import { Icon, IconProps } from '@/components/Icon';
import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import { componentTheme } from '@/styles/RelatedArticleSlider';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions, mergeProps } from '@/utils/merge';
import { RelatedArticleSlider } from './RelatedArticleSlider';

export interface StandaloneRelatedArticleSliderProps
  extends StandaloneComponentProps<
    typeof RelatedArticleSlider,
    {
      $arrow?: IconProps['options'];
    }
  > {
  slider?: ArticleSliderProps;
  link?: OptionalLinkProps;
}

export const StandaloneRelatedArticleSlider: StandaloneComponent<StandaloneRelatedArticleSliderProps> = ({
  slider,
  link,
  options,
  ...props
}) => {
  const { $arrow, ...$base } = options ?? {};

  const defaultHeadline = (
    <OptionalLink
      content={
        <>
          <span>LÄS ARTIKLAR OM ÄMNET</span>
          <Icon name="sliderForwardArrow" options={mergeOptions({ theme: componentTheme('icon') }, $arrow)} />
        </>
      }
      {...mergeProps(
        {
          options: { className: 'flex' },
        },
        link,
      )}
    />
  );

  return (
    <RelatedArticleSlider {...$base} {...props}>
      <ArticleSlider
        {...mergeProps(
          {
            headline: defaultHeadline,
            options: {
              theme: componentTheme('articleSlider'),
              $arrow: {
                name: 'sliderForwardArrow',
              },
            },
          },
          slider,
        )}
      />
    </RelatedArticleSlider>
  );
};

export default StandaloneRelatedArticleSlider;
