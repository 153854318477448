/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "articleSlider": {
    "variant_default": "relative",
    "navigation_variant_default": "hidden",
    "arrow_colors_primary": "text-gray-300 bg-gray-100 xl:bg-transparent",
    "arrow_size_medium": "w-8 h-8 p-2 xl:p-0",
    "arrow_variant_right": "hidden md:block absolute top-[50%] -translate-y-full right-10 xl:-right-10 z-10 cursor-pointer rounded-full xl:rounded-none",
    "arrow_variant_left": "hidden md:block absolute top-[50%] -translate-y-full left-10 xl:-left-10 z-10 cursor-pointer rotate-180 rounded-full xl:rounded-none"
  },
  "icon": {
    "size_small": "ml-1 w-1.75"
  }
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "RelatedArticleSlider");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;